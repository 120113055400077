import type { ComponentProps } from 'react';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './select.module.scss';

type SelectProps<T extends string> = Omit<
  ComponentProps<'select'>,
  'value' | 'onChange' | 'id'
> & {
  id: string;
  className?: string;
  classNameWrap?: string;
  label: string;
  isLabelVisible?: boolean;
  placeholder?: string;
  value: T;
  variant?: 'grey' | 'grey-border' | 'default' | 'white' | 'white-bold';
  onChange: (value: T) => void;
};
type OptionProps = Omit<ComponentProps<'option'>, 'value' | 'label'> & {
  value: string;
  label: string;
  disabled?: boolean;
};

const Select = <T extends string>({
  label,
  isLabelVisible,
  placeholder,
  value,
  onChange,
  className,
  classNameWrap,
  children,
  variant,
  id,
  ...rest
}: SelectProps<T>) => {
  return (
    <div className={classNameWrap}>
      <label
        className={isLabelVisible ? $.Label : 'visually-hidden'}
        htmlFor={id}
      >
        {label}
      </label>
      <select
        {...rest}
        className={cx(
          $.select,
          variant === 'grey' && $.selectGrey,
          variant === 'grey-border' && $.selectGreyBorder,
          variant === 'white' && $.selectWhite,
          variant === 'white-bold' && $.selectWhiteBold,
          className && className,
        )}
        id={id}
        value={value}
        onChange={(ev) => onChange(ev.target.value as T)}
        // Firefox propegates the old value down the event.
        onMouseDown={(ev) => {
          ev.stopPropagation();
        }}
      >
        {placeholder && (
          <Select.Option disabled value="" label={placeholder} aria-hidden />
        )}
        {children}
      </select>
    </div>
  );
};

const SelectOption = ({ value, label, disabled, ...rest }: OptionProps) => {
  return (
    <option {...rest} value={value} disabled={disabled}>
      {label}
    </option>
  );
};

Select.Option = SelectOption;

export default Select;

import { ParsedUrlQuery } from 'querystring';
import { ProductDetailPlayerModel } from '~source/core/models/components/atoms/product-detail-player-model';
import { PrintingType } from '~source/core/models/components/organisms/product-details';
import isCharactersOnly from '~source/ui/utils/checks/is-character-only';
import isNumber from '~source/ui/utils/checks/is-number';
import normalizeText from '~source/ui/utils/formatters/normalize-text';
import { getSingleValue } from '~source/ui/utils/urls/url-query';
import getPrintingTypeFromParamValue from './get-printing-type-from-param-value';
import hasInitialInputInQuery from './has-initial-input-in-query';

interface Params {
  players: ProductDetailPlayerModel[] | null;
  defaultPlayerShirtNumber: number | null;
  query: ParsedUrlQuery;
  maxNameLength: number;
}

export interface InitialInputType {
  initialPrintingType: PrintingType;
  initialName: string;
  initialNumber: string;
}

export function getNumberInQuery(query: ParsedUrlQuery) {
  // You can only choose a number between 0 and 99
  const number = getSingleValue(query, 'number')?.substring(0, 2);
  return number && isNumber(number) ? normalizeText(number) : '';
}

export function getNameInQuery(query: ParsedUrlQuery, maxNameLength: number) {
  const name = getSingleValue(query, 'name')?.substring(0, maxNameLength);
  return name && isCharactersOnly(name) ? normalizeText(name) : '';
}

export function getPlayer(players: ProductDetailPlayerModel[], number: number) {
  return players.find((player) => player.number === number);
}

export default function getInitialInput({
  players,
  defaultPlayerShirtNumber,
  query,
  maxNameLength,
}: Params): InitialInputType {
  const initialInput: InitialInputType = {
    initialPrintingType: 'none',
    initialName: '',
    initialNumber: '',
  };

  const hasInitialQuery = hasInitialInputInQuery(query);
  if (!players) return initialInput;
  if (hasInitialQuery) {
    const type = getPrintingTypeFromParamValue(query.type);
    const name = getNameInQuery(query, maxNameLength);
    const number = getNumberInQuery(query);
    const player = getPlayer(players, parseInt(number, 10));

    if (type === 'player' && player) {
      initialInput.initialPrintingType = 'player';
      initialInput.initialName = player.name;
      initialInput.initialNumber = player.number.toString();
      return initialInput;
    }
    initialInput.initialPrintingType = 'custom';
    initialInput.initialName = name;
    initialInput.initialNumber = number;
    return initialInput;
  }

  const player = getPlayer(players, defaultPlayerShirtNumber ?? 10); // Use Tadić if no default
  if (player) {
    initialInput.initialPrintingType = 'player';
    initialInput.initialName = player.name;
    initialInput.initialNumber = player.number.toString();
  }

  return initialInput;
}

import { useEffect, useState } from 'react';
import { ProductDetails } from '~source/core/models/components/organisms/product-details';
import { splitProdIdLineIdString } from '~source/ui/components/organisms/shirt-configurator/utils/prod-id-line-id-util';

export default function useNameInputMaxLength(
  bundleResponse: ProductDetails | null,
  selectedStockProductIdLineIdString: string | null,
) {
  const [nameInputMaxLength, setNameInputMaxLength] = useState<number>(8);

  const getNameInputMaxLength = () => {
    if (
      bundleResponse &&
      bundleResponse.sizes &&
      selectedStockProductIdLineIdString
    ) {
      const { productId } = splitProdIdLineIdString(
        selectedStockProductIdLineIdString,
      );
      const selectedStockProduct = bundleResponse.sizes.find(
        (product) => product.id === productId,
      );
      setNameInputMaxLength(selectedStockProduct?.maxPrintLength || 8);
    }
  };

  useEffect(() => {
    getNameInputMaxLength();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStockProductIdLineIdString]);

  return nameInputMaxLength;
}

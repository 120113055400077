import { useEffect, useState } from 'react';
import { ShirtType } from '~source/core/models/components/atoms/size';
import { ProductDetails } from '~source/core/models/components/organisms/product-details';

export default function useShirtType(bundleResponse: ProductDetails | null) {
  const [shirtType, setShirtType] = useState<ShirtType | null>(
    bundleResponse?.shirtType || null,
  );

  const getShirtType = () => {
    if (bundleResponse) setShirtType(bundleResponse?.shirtType);
  };

  useEffect(() => {
    getShirtType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundleResponse]);

  return shirtType;
}

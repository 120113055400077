import * as React from 'react';
import { PAYMENT_TIMEOUT } from '~source/constants';
import Translate from '~source/ui/components/atoms/translate/translate';
import useCountdown from '~source/ui/hooks/helper/useCountdown/useCountdown';
import { useOrder } from '~source/ui/hooks/order/useOrder/useOrder';

interface Props {
  label: string;
}

export const PaymentTimerLabel: React.FC<Props> = ({ label }) => {
  const { orderStatus, paymentTimestamp, updateOrder, setPaymentTimestamp } =
    useOrder();
  const {
    timer,
    isExpired: isPaymentExpired,
    setIsExpired,
  } = useCountdown(paymentTimestamp, PAYMENT_TIMEOUT);

  const clearPaymentTimer = React.useCallback(() => {
    setPaymentTimestamp(null);
    setIsExpired(false);
  }, [setPaymentTimestamp, setIsExpired]);

  /** Trigger pending status when payment timer is expired */
  React.useEffect(() => {
    if (isPaymentExpired && orderStatus === 'payment') {
      updateOrder({ status: 'pending' });
      clearPaymentTimer();
    }
  }, [isPaymentExpired, orderStatus, updateOrder, clearPaymentTimer]);

  return <Translate code={label} timer={timer} />;
};

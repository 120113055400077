import { ParsedUrlQuery } from 'querystring';
import getSingleValue from '~source/ui/utils/getters/get-single-value';
import getPrintingTypeFromParamValue from './get-printing-type-from-param-value';

export default function hasInitialInputInQuery(query: ParsedUrlQuery) {
  const printingType = getPrintingTypeFromParamValue(query.type);
  const queryName = getSingleValue(query.name);
  const queryNumber = getSingleValue(query.number);

  return !!(printingType && (queryName || queryNumber));
}

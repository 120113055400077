export default function createNumberOptions(
  n: number,
  generateElement = (index: number) => (
    <option key={index} value={index}>
      {index}
    </option>
  ),
) {
  const elements = [];
  for (let i = 0; i < n; i += 1) {
    elements.push(generateElement(i));
  }
  return elements;
}

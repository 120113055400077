import React from 'react';

type Countdown = {
  timer: string;
  isExpired: boolean;
  setIsExpired: React.Dispatch<React.SetStateAction<boolean>>;
};

const calculateRemainingTime = (
  timestamp: number | null,
  duration: number,
): number => {
  if (!timestamp) return 0;

  const targetTime = timestamp + duration;
  const currentTime = Date.now();

  // Return the remaining time in seconds by subtracting current time from target time
  return Math.floor((targetTime - currentTime) / 1000);
};

const useCountdown = (
  timestamp: number | null,
  duration: number,
): Countdown => {
  const [seconds, setSeconds] = React.useState<number>(
    calculateRemainingTime(timestamp, duration), // Initialize seconds state with remaining time
  );
  const [isExpired, setIsExpired] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!timestamp) return setIsExpired(false);

    const intervalId = setInterval(() => {
      const newSeconds = calculateRemainingTime(timestamp, duration);
      setSeconds(newSeconds);

      if (newSeconds <= 0) {
        setIsExpired(true);
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timestamp, duration, seconds]);

  const minutes = Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0');
  const displaySeconds = (seconds % 60).toString().padStart(2, '0');

  const timer = !isExpired ? `${minutes}:${displaySeconds}` : '00:00';

  return { timer, isExpired, setIsExpired };
};

export default useCountdown;

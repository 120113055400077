import { Badge } from '~source/core/models/components/atoms/badge';

export default function isEredivisieBadge(
  badges: Badge[] | null,
  selectedBadgeId: number | null,
) {
  if (!selectedBadgeId) return false;
  return badges
    ?.find(({ id }) => id === selectedBadgeId)
    ?.name.includes('Eredivisie');
}

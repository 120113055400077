import { useState } from 'react';

export default function useSizeSelector() {
  const [
    selectedStockProductIdLineIdString,
    setSelectedStockProductIdLineIdString,
  ] = useState<string | null>(null);

  const sizeOnChangeHandler = (productIdLineIdString: string) => {
    if (productIdLineIdString) {
      setSelectedStockProductIdLineIdString(productIdLineIdString);
    } else {
      setSelectedStockProductIdLineIdString(null);
    }
  };

  const reset = () => setSelectedStockProductIdLineIdString(null);

  return { sizeOnChangeHandler, selectedStockProductIdLineIdString, reset };
}

import { useState } from 'react';

export default function useProductSelector(initialBundleId: number | null) {
  const [selectedBundleProductId, setSelectedBundleProductId] = useState<
    number | null
  >(initialBundleId);

  const productOnChangeHandler = (productLineId: string) => {
    if (productLineId) {
      setSelectedBundleProductId(Number(productLineId));
    } else {
      setSelectedBundleProductId(null);
    }
  };

  return { productOnChangeHandler, selectedBundleProductId };
}

function createProdIdLineIdString(prodId: number, bundleLineId: number) {
  return `${prodId}-${bundleLineId}`;
}

function splitProdIdLineIdString(string: string) {
  const res = string.split('-');
  return {
    productId: Number(res[0]),
    lineId: Number(res[1]),
  };
}

export { createProdIdLineIdString, splitProdIdLineIdString };

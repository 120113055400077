import * as React from 'react';
import { SHIRT_TYPES } from '~source/constants';
import { Badge } from '~source/core/models/components/atoms/badge';
import { ShirtType } from '~source/core/models/components/atoms/size';
import { PrintingType } from '~source/core/models/components/organisms/product-details';
import { LocaleLanguageType } from '~source/core/models/unions/locale';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import useProductDetails from '~source/ui/hooks/product/useProductDetails/useProductDetails';
import isEredivisieBadge from '~source/ui/utils/checks/is-eredivisie-badge';
import { isShirtNameValid } from '~source/ui/utils/checks/name-validation';

// TODO(refactor): Move this file to the hooks folder inside the component product-detail-block
interface Props {
  maxPrintingLength: number;
  initialName?: string;
  initialNumber?: string;
  initialPrintingType?: PrintingType;
  shirtType: ShirtType | null;
  badges: Badge[] | null;
  locale: LocaleLanguageType;
}

export default function useProductDetailConfigurator({
  maxPrintingLength,
  initialName,
  initialNumber,
  initialPrintingType,
  shirtType,
  badges,
  locale,
}: Props) {
  const [type, setType] = React.useState<PrintingType>(
    initialPrintingType ?? 'none',
  );
  const [selectedBadgeId, setSelectedBadgeId] = React.useState<number | null>(
    null,
  );
  const [printing, setPrinting] = React.useState(initialName ?? '');
  const [printingValid, setPrintingValid] = React.useState(true);
  const [backNumber, setBackNumber] = React.useState(initialNumber ?? '');
  const [backNumberValid, setBackNumberValid] = React.useState(true);
  const [error, setError] = React.useState('');

  const selectedBadge = React.useMemo(
    () => badges?.find(({ id }) => id === selectedBadgeId),
    [badges, selectedBadgeId],
  );

  const t = useTranslate();
  const { value: selectedBadgeDetails } = useProductDetails(
    selectedBadge?.id ?? null,
    locale,
  );

  const handleSelectPrintingChange = (nameType: PrintingType) => {
    if (nameType === 'none') {
      setPrinting('');
      setBackNumber('');
    }
    setPrintingValid(true);
    setBackNumberValid(true);
    setType(nameType);
  };

  const validatePrinting = () => {
    const isTooLong = !!(printing && printing.length > maxPrintingLength);
    if (printing && !isTooLong) {
      setPrintingValid(isShirtNameValid(printing, type));
      return;
    }
    if (!printing || type === 'player') {
      setPrintingValid(true);
      setError('');
      return;
    }
    setError(t('PRODUCT_DETAILS_PRINTING_INVALID'));
    setPrintingValid(false);
  };

  const validateBackNumber = () => {
    const isValid =
      backNumber !== ''
        ? parseInt(backNumber, 10) >= 0 && parseInt(backNumber, 10) <= 99
        : true;
    setBackNumberValid(isValid);
    !isValid && setError(t('PRODUCT_DETAILS_NUMBER_INVALID'));
  };

  const hasAdditionalLogo = React.useMemo(() => {
    const isShirtWithLogo =
      shirtType === SHIRT_TYPES.homeNew ||
      shirtType === SHIRT_TYPES.away ||
      shirtType === SHIRT_TYPES.awayNew ||
      shirtType === SHIRT_TYPES.keeperNew;
    const hasEredivisieBadge = isEredivisieBadge(badges, selectedBadgeId);
    return (
      !!printing && !!backNumber && !!hasEredivisieBadge && isShirtWithLogo
    );
  }, [backNumber, badges, printing, selectedBadgeId, shirtType]);

  const reset = () => {
    setSelectedBadgeId(null);
    setPrinting('');
    setBackNumber('');
  };

  const isValid = React.useMemo(() => {
    if (type === 'custom') {
      return printingValid && backNumberValid;
    }
    return true;
  }, [printingValid, backNumberValid, type]);

  React.useEffect(() => {
    validatePrinting();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxPrintingLength]);

  return {
    type,
    handleSelectPrintingChange,
    setSelectedBadgeId,
    selectedBadge,
    selectedBadgeDetails,
    printing,
    backNumber,
    setPrinting,
    addBackNumber: setBackNumber,
    isValid,
    reset,
    validatePrinting,
    printingValid,
    validateBackNumber,
    backNumberValid,
    error,
    hasAdditionalLogo,
  };
}

import cx from 'classnames';
import * as React from 'react';
import $ from './number-text-input.module.scss';

type Props = Omit<React.ComponentProps<'input'>, 'type' | 'onChange' | 'id'> & {
  id: string;
  className?: string;
  classNameWrap?: string;
  variant?: 'grey' | 'grey-border' | 'default' | 'white';
  isValid?: boolean;
  isLabelVisible?: boolean;
  label: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  type?: string;
  userHint?: string | React.ReactElement;
  errorMessage?: string | React.ReactElement;
};

const NumberTextInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      placeholder,
      onChange,
      onBlur,
      isValid = true,
      isLabelVisible,
      userHint,
      className,
      classNameWrap,
      type,
      variant,
      errorMessage,
      id,
      ...rest
    },
    ref,
  ) => {
    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (ev) =>
      onChange?.(ev.target.value);
    const handleOnBlur: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
      onBlur?.(ev.target.value);
    };
    const message = userHint || errorMessage;
    return (
      <div className={classNameWrap}>
        <label
          className={isLabelVisible ? $.Label : 'visually-hidden'}
          htmlFor={id}
        >
          {label}
        </label>
        <input
          ref={ref}
          className={cx(
            $.input,
            variant === 'grey' && $.inputGrey,
            variant === 'grey-border' && $.inputGreyBorder,
            variant === 'white' && $.inputWhite,
            className && className,
            !isValid && errorMessage && $.inputError,
          )}
          id={id}
          type={type ?? 'text'}
          placeholder={isLabelVisible ? placeholder : label}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          aria-invalid={!isValid}
          aria-describedby={id && !isValid ? `${id}-message` : undefined}
          {...rest}
        />
        {message && (
          <p
            className={cx(
              userHint && $.hint,
              errorMessage && $.error,
              isValid && 'is-hidden',
            )}
            aria-live="assertive"
            id={id ? `${id}-message` : undefined}
            role="alert"
          >
            {message}
          </p>
        )}
      </div>
    );
  },
);

export default NumberTextInput;

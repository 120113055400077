import { AsyncStatus } from '~source/ui/hooks/product/useAddProductToOrder';

export default function getAddToCartButtonLabel(
  asyncStatus: AsyncStatus,
  areMultipleProducts?: boolean,
  areMultipleProductsSelected?: boolean,
) {
  if (asyncStatus === 'loading') return 'ADDING_TO_BASKET';
  if (areMultipleProducts && !areMultipleProductsSelected)
    return 'ORDER_SELECTION';
  if (areMultipleProducts && areMultipleProductsSelected)
    return 'ADD_SELECTION_TO_BASKET';
  return 'ADD_TO_BASKET';
}

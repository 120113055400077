import { PrintingType } from '~source/core/models/components/organisms/product-details';

export const specialCharacterFormat =
  // eslint-disable-next-line no-useless-escape
  /[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?\d[\]]+/g;
export const shirtFormat = /[^A-Za-z\s]/;
// We need to make an exception for F. Conceicao
export const specialCharacterExcludingDotFormat =
  // eslint-disable-next-line no-useless-escape
  /[!@#$%^&*()_+=\[\]{};':"\\|,<>\/?\d[\]]+/g;
/*
Name cannot contain special characters
Name cannot contain emoji's (Code from: https://www.regextester.com/106421)
*/
const emojiFormat =
  /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;

export function isShirtNameValid(name: string, type: PrintingType) {
  const format =
    type === 'player'
      ? specialCharacterExcludingDotFormat
      : specialCharacterFormat;
  return (
    format.test(name) === false &&
    emojiFormat.test(name) === false &&
    shirtFormat.test(name) === false
  );
}

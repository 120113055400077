import dynamic from 'next/dynamic';
import React from 'react';
import { SHIRT_TYPES } from '~source/constants';
import { ShirtType } from '~source/core/models/components/atoms/size';
import ShirtCanvas2D from '~source/ui/components/atoms/shirt-canvas-2d/shirt-canvas-2d';
import { useInitData } from '~source/ui/hooks/useApplicationConfig';
import getConfiguratorImageUrl from '~source/ui/utils/getters/get-configurator-images-url';
import $ from './shirt-canvas.module.scss';

const ShirtCanvas3D = dynamic(
  () => import('~source/ui/components/atoms/shirt-canvas-3d/shirt-canvas-3d'),
  { ssr: false },
);

interface Props {
  printingName: string;
  printingNumber: string;
  shirtType: ShirtType;
  tsdBadge?: string | null;
  hasAdditionalLogo?: boolean;
  isInShirtCarousel?: boolean;
}

const ShirtCanvas = ({
  printingName,
  printingNumber,
  shirtType,
  tsdBadge,
  hasAdditionalLogo = false,
  isInShirtCarousel = false,
}: Props) => {
  const shirtConfigurations = useInitData()?.shirtConfigurations;
  const shirtImages = React.useMemo(() => {
    return getConfiguratorImageUrl({
      shirtType,
      shirtConfigurations,
      hasAdditionalLogo,
    });
  }, [shirtType, shirtConfigurations, hasAdditionalLogo]);

  const [type, setType] = React.useState<'2D' | '3D'>(() =>
    shirtType === SHIRT_TYPES.homeNew ||
    shirtType === SHIRT_TYPES.awayNew ||
    shirtType === SHIRT_TYPES.thirdNew
      ? '3D'
      : '2D',
  );

  React.useEffect(() => {
    if (
      shirtType === SHIRT_TYPES.homeNew ||
      shirtType === SHIRT_TYPES.awayNew ||
      shirtType === SHIRT_TYPES.thirdNew
    ) {
      setType('3D');
    }
  }, [shirtType]);

  if (type === '3D') {
    return (
      <ShirtCanvas3D
        printingName={printingName}
        printingNumber={printingNumber}
        shirtType={shirtType}
        tsdBadge={tsdBadge}
        hasAdditionalLogo={hasAdditionalLogo}
        onError={() => setType('2D')} // Fall back to 2D if 3D fails
      />
    );
  }

  return (
    <div className={$.container}>
      <ShirtCanvas2D
        printingName={printingName}
        printingNumber={printingNumber}
        shirtType={shirtType}
        frontImage={shirtImages.front}
        backImage={shirtImages.back}
        isInShirtCarousel={isInShirtCarousel}
      />
    </div>
  );
};

export default ShirtCanvas;

import React from 'react';

export default function useArrayState<T>(defaultArray: T[], unique = false) {
  const [array, setArray] = React.useState<T[]>(defaultArray);

  const add = (item: T) =>
    setArray((prev) =>
      !unique || !prev.includes(item) ? [...prev, item] : prev,
    );

  const remove = (item: T) => setArray(array.filter((_item) => _item !== item));

  const clear = () => setArray([]);

  const replaceAll = (arr: T[]) => {
    setArray(arr);
  };

  return [array, add, remove, clear, replaceAll] as const;
}

import { useState } from 'react';
import { ProductTeaser } from '~source/core/models/components/molecules/product-teaser';
import { searchProductsByCollection } from '~source/core/services/eva/api/search-products';
import useLocale from '~source/ui/hooks/helper/useLocale/useLocale';

const useSearchProductsByCollectionAsync = (collection: string) => {
  const locale = useLocale();
  const [status, setStatus] = useState('idle');
  const [value, setValue] = useState<ProductTeaser[] | null>(null);
  const [error, setError] = useState(null);

  const execute = async () => {
    setStatus('pending');
    setValue(null);
    setError(null);

    try {
      const response = await searchProductsByCollection({
        collection,
        locale,
      }).promise;
      setValue(response ? response.products : []);
      setStatus('success');
    } catch (err: any) {
      setError(err);
      setStatus('error');
    }
  };

  return { execute, status, value, error };
};

export default useSearchProductsByCollectionAsync;

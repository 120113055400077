export default function formatPrice(
  price: number | string | null,
  showCurrency = true,
) {
  if (!price) return '';
  let splittedPrice =
    typeof price === 'string'
      ? price.split(',')
      : price?.toFixed(2).split('.') || ['0', '00'];
  const showMinus = splittedPrice[0].includes('-');
  if (showMinus) {
    splittedPrice = [splittedPrice[0].replace('-', ''), splittedPrice[1]];
  }

  return `${showMinus ? '-' : ''}${showCurrency ? '€' : ''}${
    splittedPrice[0]
  },${splittedPrice[1]}`;
}

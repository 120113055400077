import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import type { ProductDetailPlayerModel } from '~source/core/models/components/atoms/product-detail-player-model';
import type {
  SizeModel,
  SizeModelConfigurable,
} from '~source/core/models/components/atoms/size';
import type { ProductTeaser } from '~source/core/models/components/molecules/product-teaser';
import type { ShirtConfigurator as ShirtConfiguratorModel } from '~source/core/models/components/molecules/shirt-configurator';
import type { PrintingType } from '~source/core/models/components/organisms/product-details';
import { sendECommerce } from '~source/core/services/e-commerce/e-commerce';
import Button from '~source/ui/components/atoms/buttons/button/button';
import ErrorMessage from '~source/ui/components/atoms/error-message/error-message';
import NumberTextInput from '~source/ui/components/atoms/form/number-text-input/number-text-input';
import Select from '~source/ui/components/atoms/form/select/select';
import { PaymentTimerLabel } from '~source/ui/components/atoms/payment-timer-label/payment-timer-label';
import ShirtCanvas from '~source/ui/components/atoms/shirt-canvas/shirt-canvas';
import Translate from '~source/ui/components/atoms/translate/translate';
import Container from '~source/ui/components/containers/container/container';
import DynamicAddedToBasket from '~source/ui/components/molecules/added-to-basket/dynamic-added-to-basket';
import useAddedMessage from '~source/ui/components/organisms/product-detail-components/hero-block/hooks/useAddedMessage';
import useNameInputMaxLength from '~source/ui/components/organisms/shirt-configurator/hooks/use-name-input-max-length';
import useProductSelector from '~source/ui/components/organisms/shirt-configurator/hooks/use-product-selector';
import useShirtType from '~source/ui/components/organisms/shirt-configurator/hooks/use-shirt-type';
import useSizeSelector from '~source/ui/components/organisms/shirt-configurator/hooks/use-size-selector';
import createNumberOptions from '~source/ui/components/organisms/shirt-configurator/utils/create-number-options';
import getInitialInput from '~source/ui/components/organisms/shirt-configurator/utils/get-initial-input';
import {
  createProdIdLineIdString,
  splitProdIdLineIdString,
} from '~source/ui/components/organisms/shirt-configurator/utils/prod-id-line-id-util';
import { useCart } from '~source/ui/hooks/cart/useCart/useCart';
import useDisplayBundlePrice from '~source/ui/hooks/formatter/useDisplayBundlePrice/useDisplayBundlePrice';
import useArrayState from '~source/ui/hooks/helper/useArrayState/useArrayState';
import useLocale from '~source/ui/hooks/helper/useLocale/useLocale';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import { useOrder } from '~source/ui/hooks/order/useOrder/useOrder';
import useAddProductToOrder from '~source/ui/hooks/product/useAddProductToOrder';
import useProductDetailConfigurator from '~source/ui/hooks/product/useProductDetailConfigurator/useProductDetailConfigurator';
import useProductDetails from '~source/ui/hooks/product/useProductDetails/useProductDetails';
import useSearchProductsByCollectionAsync from '~source/ui/hooks/product/useSearchProductsByCollectionAsync';
import isClientSide from '~source/ui/hooks/ui/useClientSide/useClientSide';
import useOnClickOutside from '~source/ui/hooks/ui/useOnClickOutside/useOnClickOutside';
import { isProductAvailable } from '~source/ui/utils/checks/is-product-available';
import sendDYEvent from '~source/ui/utils/dynamic-yield/send-dy-event';
import formatPrice from '~source/ui/utils/formatters/format-price';
import getAddtoCartButtonLabel from '~source/ui/utils/getters/get-add-to-cart-button-label';
import showAvailabilityDate from '~source/ui/utils/product/show-availability-date';
import $ from './shirt-configurator.module.scss';

function getSize(
  sizes: (SizeModel | SizeModelConfigurable)[] | null,
  productLineId: string | null,
) {
  if (!sizes || !productLineId) return null;

  const selectedSizeId = splitProdIdLineIdString(productLineId).productId;

  return sizes.find((size) => size.id === selectedSizeId) || null;
}

function ShirtConfigurator(props: ShirtConfiguratorModel) {
  const t = useTranslate();
  const router = useRouter();
  const {
    title,
    description,
    price,
    initialBundleId,
    defaultPlayerShirtNumber,
  } = props;

  const [errors, addError, , clearErrors] = useArrayState([] as string[], true);
  const { orderStatus } = useOrder();
  const { shoppingCart } = useCart();
  const locale = useLocale();

  // #region Search for bundles
  const {
    execute: searchProductsByCollection,
    status: searchProductsByCollectionStatus,
    value: productTypes,
  } = useSearchProductsByCollectionAsync('shirt_configurator'); // TODO: retrieve collection property from API
  // Retrieve shirts on mount
  useEffect(() => {
    searchProductsByCollection().catch(() => null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // #endregion

  const { selectedBundleProductId, productOnChangeHandler } =
    useProductSelector(initialBundleId);
  const selectedBundleProductIdValue = selectedBundleProductId
    ? selectedBundleProductId.toString()
    : '';
  const { status: getBundleProductDetailsStatus, value: bundleResponse } =
    useProductDetails(selectedBundleProductId, locale);

  // #region Size
  const {
    sizeOnChangeHandler,
    selectedStockProductIdLineIdString,
    reset: resetSize,
  } = useSizeSelector();
  const selectedSize = getSize(
    bundleResponse?.sizes || null,
    selectedStockProductIdLineIdString,
  );
  // #endregion

  // #region Configurator
  const shirtType = useShirtType(bundleResponse);
  const nameInputMaxLength = useNameInputMaxLength(
    bundleResponse,
    selectedStockProductIdLineIdString,
  );
  const {
    type: printingType,
    handleSelectPrintingChange,
    setSelectedBadgeId,
    selectedBadge,
    selectedBadgeDetails,
    isValid: isConfiguratorValid,
    printing,
    validatePrinting,
    backNumber,
    setPrinting,
    addBackNumber,
    reset: resetConfigurator,
    error: configuratorError,
    hasAdditionalLogo,
  } = useProductDetailConfigurator({
    initialName: router.query.shirtName as string | undefined,
    initialNumber: router.query.shirtNumber as string | undefined,
    maxPrintingLength: nameInputMaxLength,
    badges: bundleResponse?.badges ?? null,
    shirtType,
    locale,
  });

  const setPlayer = (number: string) => {
    const selectedPlayer = bundleResponse?.players?.find(
      (player) => player.number.toString() === number,
    ) as ProductDetailPlayerModel;
    setPrinting(selectedPlayer.name);
    addBackNumber(selectedPlayer.number.toString());
  };
  const isInitialBundleLoaded = React.useRef(false);
  React.useEffect(() => {
    if (isInitialBundleLoaded.current || !bundleResponse) return;
    isInitialBundleLoaded.current = true;

    const { initialName, initialNumber, initialPrintingType } = getInitialInput(
      {
        players: bundleResponse.players,
        query: router.query,
        defaultPlayerShirtNumber,
        maxNameLength: nameInputMaxLength,
      },
    );
    setPrinting(initialName);
    addBackNumber(initialNumber);
    handleSelectPrintingChange(initialPrintingType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundleResponse]);
  // #endregion

  // #region Display price
  const initialPrice = {
    original: price,
    sale: null,
  };
  const { displayPrice, setBasePrice } = useDisplayBundlePrice({
    price: initialPrice,
    selectedBadge: selectedBadge ?? null,
    inputPrinting: printing,
    inputBackNumber: backNumber,
    printingOptionsInfo: bundleResponse?.printingOptionsInfo || null,
  });
  useEffect(() => {
    if (!bundleResponse) return;
    setBasePrice(bundleResponse.price);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundleResponse]);

  // set new base price and send analytics data on configurator product view
  useEffect(() => {
    if (selectedBundleProductId)
      sendECommerce('view_item', selectedBundleProductId);
    if (!selectedBundleProductId) setBasePrice(initialPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBundleProductId]);
  // #endregion

  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  useOnClickOutside(buttonRef, () => clearErrors());

  const reset = () => {
    resetConfigurator();
    resetSize();
  };

  // #region submit
  const {
    addToBasket,
    error: basketError,
    status,
  } = useAddProductToOrder({
    backNumber,
    printing,
    productTypes: bundleResponse?.productTypes ?? null,
    productId: selectedBundleProductId,
    printingOptionsInfo: bundleResponse?.printingOptionsInfo || null,
    printingType,
    sizes: bundleResponse?.sizes ?? null,
    requiredLogo: hasAdditionalLogo ? bundleResponse?.requiredLogo : null,
    selectedSizeIds: selectedSize ? [selectedSize.id] : [],
    selectedBadge,
  });
  const { isAddedMessageVisible, showAddedMessage, hideAddedMessage } =
    useAddedMessage();
  const isValid = useCallback(
    () => selectedStockProductIdLineIdString && isConfiguratorValid,
    [selectedStockProductIdLineIdString, isConfiguratorValid],
  );
  const handleSubmit = async (e: React.FormEvent<Element>) => {
    e.preventDefault();
    if (!isValid()) {
      let message = '';
      // @TODO(refactor): Move error messages to the hook itself
      if (!selectedStockProductIdLineIdString) message = 'ERROR_SELECT_SIZE';
      if (!selectedBundleProductId) message = 'ERROR_SELECT_SHIRT';
      if (!isConfiguratorValid) message = 'PRODUCT_DETAILS_PRINTING_INVALID';
      message && addError(t(message));
      return;
    }
    await addToBasket();
  };

  React.useEffect(() => {
    if (status !== 'success') return;
    showAddedMessage();

    if (!bundleResponse || !selectedStockProductIdLineIdString) return;

    const selectedSizeId = splitProdIdLineIdString(
      selectedStockProductIdLineIdString,
    ).productId;
    const { currencyId, sizes, backendId } = bundleResponse;
    sendDYEvent('Add to Cart', {
      price: displayPrice,
      currencyId,
      productId:
        sizes?.find((size) => size.id === selectedSizeId)?.backendId ||
        backendId,
      size: getSize(sizes, selectedStockProductIdLineIdString)?.label || null,
      shoppingCart,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  // #endregion

  const handleProductChange = (nextProductId: string) => {
    reset();
    productOnChangeHandler(nextProductId);
  };

  const handlePrintingTypeChange = (value: PrintingType) => {
    resetConfigurator();
    handleSelectPrintingChange(value);
  };

  const initialRender = React.useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (!isAddedMessageVisible) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddedMessageVisible]);

  const showAvailableDate = React.useMemo(() => {
    return showAvailabilityDate(selectedSize?.availability);
  }, [selectedSize]);

  const allErrors = [...errors, basketError, configuratorError].filter(
    (error) => error !== '',
  );

  const showCustomNameField =
    printingType === 'custom' ||
    (printingType === 'player' && !bundleResponse?.players);

  const isButtonDisabled = status === 'loading' || orderStatus === 'payment';
  const buttonLabel = getAddtoCartButtonLabel(status);
  const ButtonLabel =
    orderStatus === 'payment' ? (
      <PaymentTimerLabel label="BUTTON_PAYMENT_IN_PROCESS" />
    ) : (
      <Translate code={buttonLabel} />
    );

  const isClient = isClientSide();

  return (
    <section className={$.configurator}>
      <Container>
        <div className={$.container}>
          <div className={$.content}>
            <h2 className={$.title}>{title}</h2>
            <p className={$.description}>{description}</p>
            {(displayPrice.sale || displayPrice.original) && (
              <span className={$.price}>
                {selectedBundleProductId === null && `${t('FROM')} `}
                {formatPrice(displayPrice.sale ?? displayPrice.original, false)}
              </span>
            )}
          </div>

          <div className={$.canvas}>
            {shirtType && (
              <ShirtCanvas
                printingName={printing}
                printingNumber={backNumber}
                shirtType={shirtType}
                tsdBadge={
                  selectedBadge ? selectedBadgeDetails?.tsdBadge : undefined
                }
                hasAdditionalLogo={hasAdditionalLogo}
                isInShirtCarousel
              />
            )}
          </div>

          {isAddedMessageVisible && bundleResponse ? (
            <div className={$.addedToCart}>
              <DynamicAddedToBasket
                size={
                  getSize(
                    bundleResponse.sizes,
                    selectedStockProductIdLineIdString,
                  )?.label || null
                }
                name={printing}
                badge={selectedBadge ?? null}
                backNumber={backNumber}
                hide={hideAddedMessage}
                basketLink="/cart"
                isConfiguratorItem
              />
            </div>
          ) : (
            <div>
              {searchProductsByCollectionStatus === 'pending' && (
                <span>{t('LOADING')}</span>
              )}
              {searchProductsByCollectionStatus === 'success' && (
                <form className={$.form} onSubmit={handleSubmit}>
                  <fieldset className={$.row}>
                    <Select
                      id="shirt-configurator-product-id"
                      label={t('SHIRT')}
                      value={selectedBundleProductIdValue}
                      onChange={handleProductChange}
                      variant="white-bold"
                      placeholder={t('SHIRT')}
                    >
                      {productTypes?.map((product: ProductTeaser) => (
                        <Select.Option
                          key={product.productId}
                          value={product.productId.toString()}
                          label={product.displayValue}
                        />
                      ))}
                    </Select>
                    <Select
                      id="shirt-configurator-size"
                      label={t('SIZE')}
                      onChange={sizeOnChangeHandler}
                      value={selectedStockProductIdLineIdString || ''}
                      variant="white-bold"
                      placeholder={
                        getBundleProductDetailsStatus === 'pending'
                          ? t('LOADING')
                          : t('SIZE')
                      }
                    >
                      {bundleResponse &&
                        (bundleResponse.sizes as SizeModel[]).map(
                          (product: SizeModel) => (
                            <Select.Option
                              key={product.id}
                              value={createProdIdLineIdString(
                                product.id,
                                product.productBundleLineId || 0,
                              )}
                              disabled={
                                !isProductAvailable(product.availability)
                              }
                              label={product.label}
                            />
                          ),
                        )}
                    </Select>
                  </fieldset>

                  <fieldset>
                    <Select
                      id="shirt-configurator-name-type"
                      label={t('SHIRT_CONFIGURATOR_NAME_TYPE')}
                      onChange={handlePrintingTypeChange}
                      value={printingType}
                      variant="white-bold"
                    >
                      <Select.Option
                        value="custom"
                        label={t('SHIRT_CONFIGURATOR_NAME')}
                      />
                      {bundleResponse?.players && (
                        <Select.Option
                          value="player"
                          label={t('SHIRT_CONFIGURATOR_PLAYERNAME')}
                        />
                      )}
                      <Select.Option
                        value="none"
                        label={t('PRODUCT_DETAILS_NO_PRINTING')}
                      />
                    </Select>
                  </fieldset>

                  {showCustomNameField && (
                    <fieldset>
                      <NumberTextInput
                        id="shirt-configurator-name"
                        label={t('NAME')}
                        onChange={setPrinting}
                        variant="white"
                        maxLength={nameInputMaxLength}
                        value={printing}
                        onBlur={validatePrinting}
                      />
                    </fieldset>
                  )}
                  {printingType === 'player' && bundleResponse?.players && (
                    <fieldset>
                      <Select
                        id="shirt-configurator-player-name"
                        label={t('PRODUCT_DETAILS_SELECT_PLAYER')}
                        onChange={setPlayer}
                        value={backNumber}
                        variant="white-bold"
                        placeholder={t('PRODUCT_DETAILS_SELECT_PLAYER')}
                      >
                        {bundleResponse.players.map((player) => (
                          <Select.Option
                            key={player.number}
                            value={player.number.toString()}
                            data-shirt-number={player.number}
                            label={`${player.number} ${player.name}`}
                          />
                        ))}
                      </Select>
                    </fieldset>
                  )}

                  <fieldset
                    className={
                      printingType !== 'none' && printingType !== 'player'
                        ? $.row
                        : undefined
                    }
                  >
                    {printingType !== 'none' && printingType !== 'player' && (
                      <Select
                        id="shirt-configurator-back-number"
                        label={t('SHIRT_CONFIGURATOR_NUMBER')}
                        onChange={addBackNumber}
                        value={backNumber}
                        variant="white-bold"
                        placeholder={t('SHIRT_CONFIGURATOR_NUMBER')}
                      >
                        <Select.Option
                          value=""
                          label={t('SHIRT_CONFIGURATOR_NO_NUMBER')}
                        />
                        {createNumberOptions(100, (i) => (
                          <Select.Option
                            value={i.toString()}
                            label={i.toString()}
                          />
                        ))}
                      </Select>
                    )}
                    <Select
                      id="shirt-configurator-badge-type"
                      label={t('PRODUCT_DETAILS_BADGE_TYPE')}
                      onChange={(value) => setSelectedBadgeId(+value || null)}
                      value={selectedBadge?.id.toString() ?? ''}
                      variant="white-bold"
                    >
                      <Select.Option
                        value=""
                        label={
                          getBundleProductDetailsStatus === 'pending'
                            ? t('LOADING')
                            : t('PRODUCT_DETAILS_BADGE_TYPE')
                        }
                        disabled
                        aria-hidden="true"
                      />
                      <Select.Option
                        value=""
                        label={t('PRODUCT_DETAILS_NO_BADGE')}
                      />
                      {bundleResponse?.badges?.map((product) => (
                        <Select.Option
                          key={product.id}
                          value={product.id.toString()}
                          label={product.name}
                          disabled={!isProductAvailable(product.availability)}
                        />
                      ))}
                    </Select>
                  </fieldset>

                  <hr className={$.divider} />

                  {isClient && (
                    <Button
                      variant="black"
                      type="submit"
                      disabled={isButtonDisabled}
                      className={$.button}
                      ref={buttonRef}
                    >
                      {ButtonLabel}
                    </Button>
                  )}
                  {hasAdditionalLogo && (
                    <div className={$.errorWrap}>
                      <ErrorMessage
                        message={t('WARNING_ADDITIONAL_LOGO')}
                        type="warn"
                      />
                    </div>
                  )}
                  {!!allErrors.length && (
                    <div className={$.errorWrap}>
                      <ErrorMessage
                        message={
                          allErrors.length > 1 ? allErrors : allErrors[0]
                        }
                        type="warn"
                      />
                    </div>
                  )}
                  {showAvailableDate && (
                    <div className={$.errorWrap}>
                      <ErrorMessage
                        message={
                          <Translate
                            code="PRODUCT_DETAILS_AVAILABLE_IN_FUTURE"
                            date={selectedSize?.availabilityDate}
                          />
                        }
                        type="warn"
                      />
                    </div>
                  )}
                </form>
              )}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
}

export default ShirtConfigurator;
